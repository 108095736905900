// import React from 'react';
// import css from './SectionFooter.module.css';
// import { Link } from 'react-router-dom';
// import { FormattedMessage } from '../../../../util/reactIntl';
// import { IconSocialMediaFacebook, IconSocialMediaInstagram } from '../../../../components';
// import IconSocialMediaTiktok from '../../../../components/IconSocialMediaTiktok/IconSocialMediaTiktok';

// const SectionFooter = () => {
//   return (
//     <div className={css.footer}>
//       <div className={css.socials}>
//         <a
//           href="https://www.facebook.com/profile.php?id=61557355707435"
//           style={{ margin: '0 10px 10px 0' }}
//           target="_blank"
//           aria-label="Link to Wheelplace's Facebook account"
//         >
//           <IconSocialMediaFacebook />
//         </a>
//         <a
//           href="https://www.instagram.com/wheelplace_official/"
//           target="_blank"
//           aria-label="Link to Wheelplace's Instagram account"
//           style={{ margin: '0 10px 10px 0' }}
//         >
//           <IconSocialMediaInstagram />
//         </a>
//         <a
//           href="https://www.tiktok.com/@wheelplace_official"
//           target="_blank"
//           aria-label="Link to Wheelplace's TikTok account"
//           style={{ color: 'white' }}
//         >
//           <IconSocialMediaTiktok />
//         </a>
//       </div>
//       <div className={css.col2}>
//         <Link to="/p/about">
//           <FormattedMessage id="Footer.aboutUs" />
//         </Link>
//         <Link to="/privacy-policy">
//           <FormattedMessage id="Footer.privacyPolicy" />
//         </Link>
//         <Link to="/terms-of-service">
//           <FormattedMessage id="Footer.termsOfService" />
//         </Link>
//         <Link to="/p/cookies">
//           <FormattedMessage id="Footer.cookies" />
//         </Link>
//         <Link to="/p/faq">
//           <FormattedMessage id="Footer.faq" />
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default SectionFooter;

import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            />
          </div>
          <div className={css.sloganMobile}>
            <Field data={slogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              <Field data={slogan} className={css.slogan} />
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
              </div>
            ) : null}
            <Field data={copyright} className={css.copyright} />
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;

